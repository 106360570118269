import { styled } from '@mui/material';
import { eyePosition } from '.';

const StyledPath = styled('path')(({ theme }) => ({
  fill: theme.customColors.slogan,
  animation: 'eye-blink 6s ease infinite',
  d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283-3.4574-2.67151-32.48657-25.922-32.52173-66.14209C217.443,109.6991,246.55994,86.37769,250,83.71973Z")',

  '@keyframes eye-blink': {
    '0%': {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283-3.4574-2.67151-32.48657-25.922-32.52173-66.14209C217.443,109.6991,246.55994,86.37769,250,83.71973Z")',
    },
    '0.125%': {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283-3.68286-2.99329-31.48145-26.39783-31.5155-66.14209C218.45032,110.176,246.33179,86.70117,250,83.71973Z")',
    },
    '0.25%': {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283-3.87695-3.31482-30.4762-26.85876-30.50928-66.14209C219.45764,110.63794,246.13513,87.02417,250,83.71973Z")',
    },
    '0.375%': {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283-4.04077-3.63513-29.47107-27.30469-29.503-66.14209C220.465,111.08484,245.969,87.34619,250,83.71973Z")',
    },
    '0.5%': {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283-4.20117-4.02344-28.24268-27.82935-28.27332-66.14209C221.696,111.61072,245.806,87.73633,250,83.71973Z")',
    },
    '0.625%': {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283-4.5022-4.56677-27.126-28.44641-27.15527-66.14209C222.81543,112.21851,245.515,88.269,250,83.71973Z")',
    },
    '0.75%': {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283-4.94592-5.47632-25.45105-29.4137-25.47827-66.14209C224.49438,113.187,245.06787,89.18091,250,83.71973Z")',
    },
    '0.875%': {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283a104.76814,104.76814,0,0,1,0-132.43066Z")',
    },
    '1%': {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283a112.43868,112.43868,0,0,1,0-132.43066Z")',
    },
    '1.125%': {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283a123.67812,123.67812,0,0,1,0-132.43066Z")',
    },
    '1.25%': {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283a136.79185,136.79185,0,0,1,0-132.43066Z")',
    },
    '1.375%': {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283a149.90395,149.90395,0,0,1,0-132.43066Z")',
    },
    '1.5%': {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283a170.24251,170.24251,0,0,1,0-132.43066Z")',
    },
    '1.625%': {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283a194.53487,194.53487,0,0,1,0-132.43066Z")',
    },
    '1.75%': {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283a223.138,223.138,0,0,1,0-132.43066Z")',
    },
    '1.875%': {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283a256.07738,256.07738,0,0,1,0-132.43066Z")',
    },
    '2%': {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283a310.44762,310.44762,0,0,1,0-132.43066Z")',
    },
    "2.125%": {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283a449.2223,449.2223,0,0,1,0-132.43066Z")'
    },
    "2.25%": {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283a580.5,580.5,0,0,1,0-132.43066Z")'
    },
    "2.375%": {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283-1.84094-20.23315-3.00378-42.36377-3.00623-66.14209C246.99133,126.17371,248.15527,103.99365,250,83.71973Z")'
    },
    "2.5%": {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283-1.39319-20.72046-2.22192-42.81445-2.22363-66.14209C247.77466,126.62622,248.60388,104.48315,250,83.71973Z")'
    },

    "2.625%": {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283-.99158-21.13013-1.55151-43.20068-1.55273-66.14209C248.446,127.01416,249.00635,104.89465,250,83.71973Z")'
    },
    "2.75%": {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283q-.75366-32.39721-.77014-66.14209Q249.22895,116.19605,250,83.71973Z")'
    },
    "2.875%": {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283q-.21093-33.07764-.21118-66.14209Q249.78864,116.86915,250,83.71973Z")'
    },
    "3%": {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283q.34423-32.76892.34778-66.14209Q250.34814,116.56226,250,83.71973Z")'
    },
    "3.125%": {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283,1.14221-20.97925,1.79993-43.05762,1.80127-66.14209C251.80261,126.87048,251.14465,104.74316,250,83.71973Z")'
    },
    "3.25%": {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283a632.02233,632.02233,0,0,0,3.47827-66.14209A632.01412,632.01412,0,0,0,250,83.71973Z")'
    },
    "3.375%": {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283a446.999,446.999,0,0,0,4.93164-66.14209A447.019,447.019,0,0,0,250,83.71973Z")'
    },
    "3.5%": {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283a379.21733,379.21733,0,0,0,5.82605-66.14209A379.21975,379.21975,0,0,0,250,83.71973Z")'
    },
    "3.675%": {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283a287.63741,287.63741,0,0,0,7.72668-66.14209A287.64184,287.64184,0,0,0,250,83.71973Z")'
    },
    "3.75%": {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283a243.39068,243.39068,0,0,0,0-132.43066Z")'
    },
    "3.875%": {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283a209.38934,209.38934,0,0,0,0-132.43066Z")'
    },
    "4%": {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283a190.72126,190.72126,0,0,0,0-132.43066Z")'
    },
    "4.125%": {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283a176.77787,176.77787,0,0,0,0-132.43066Z")'
    },
    "4.25%": {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283a162.53412,162.53412,0,0,0,0-132.43066Z")'
    },
    "4.375%": {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283a146.8449,146.8449,0,0,0,0-132.43066Z")'
    },
    "4.5%": {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283a132.8546,132.8546,0,0,0,0-132.43066Z")'
    },
    "4.675": {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283a122.94726,122.94726,0,0,0,0-132.43066Z")'
    },
    "4.75%": {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283a114.75362,114.75362,0,0,0,0-132.43066Z")'
    },
    "4.875%": {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283a107.89362,107.89362,0,0,0,0-132.43066Z")'
    },
    "5%": {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283,5.21741-6.16089,24.24756-30.10864,24.27332-66.14209C274.29919,113.88293,255.20618,89.86743,250,83.71973Z")'
    },
    "5.125%": {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283,4.88489-5.33838,25.6991-29.27039,25.72668-66.14209C275.75427,113.04358,254.87073,89.04248,250,83.71973Z")'
    },
    "5.25%": {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283,4.55859-4.67187,26.92749-28.561,26.95654-66.14209C276.9856,112.33337,254.54175,88.37427,250,83.71973Z")'
    },
    "5.375%": {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283,4.19836-4.01562,28.26746-27.819,28.2981-66.14209C278.32874,111.60034,254.19116,87.72852,250,83.71973Z")'
    },
    "5.5%": {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283,4.06958-3.69812,29.27258-27.391,29.30432-66.14209C279.33606,111.17139,254.0603,87.40942,250,83.71973Z")'
    },
    "5.625%": {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283,3.93066-3.41382,30.166-26.99792,30.19873-66.14209C280.23145,110.77747,253.91943,87.12378,250,83.71973Z")'
    },
    "5.75%": {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283,3.72363-3.05688,31.28284-26.49,31.31677-66.14209C281.35071,110.26843,253.70959,86.76514,250,83.71973Z")'
    },
    "5.875%": {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283,3.55591-2.80652,32.06458-26.12353,32.09937-66.14209C282.13416,109.90112,253.53979,86.51343,250,83.71973Z")'
    },
    "6%": {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283,3.39685-2.592,32.73462-25.80212,32.77014-66.14209C282.80566,109.5791,253.37866,86.2981,250,83.71973Z")'
    },
    "6.125%": {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283,3.149-2.2926,33.67285-25.34668,33.704-66.15283C283.73523,109.11426,253.13123,85.99927,250,83.71973Z")'
    },
    "6.25%": {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283,3.149-2.2926,33.67285-25.34668,33.704-66.15283C283.73523,109.11426,253.13123,85.99927,250,83.71973Z")'
    },
    "6.375%": {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283,3.149-2.2926,33.67285-25.34668,33.704-66.15283C283.73523,109.11426,253.13123,85.99927,250,83.71973Z")'
    },
    "6.5%": {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283,3.39685-2.592,32.73462-25.80212,32.77014-66.14209C282.80566,109.5791,253.37866,86.2981,250,83.71973Z")'
    },
    "6.675%": {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283,3.55591-2.80652,32.06458-26.12353,32.09937-66.14209C282.13416,109.90112,253.53979,86.51343,250,83.71973Z")'
    },
    "6.75%": {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283,3.72363-3.05688,31.28284-26.49,31.31677-66.14209C281.35071,110.26843,253.70959,86.76514,250,83.71973Z")'
    },
    "6.875%": {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283,3.93066-3.41382,30.166-26.99792,30.19873-66.14209C280.23145,110.77747,253.91943,87.12378,250,83.71973Z")'
    },
    "7%": {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283,4.06958-3.69812,29.27258-27.391,29.30432-66.14209C279.33606,111.17139,254.0603,87.40942,250,83.71973Z")'
    },
    "7.125%": {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283,4.19836-4.01562,28.26746-27.819,28.2981-66.14209C278.32874,111.60034,254.19116,87.72852,250,83.71973Z")'
    },
    "7.25%": {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283,4.55859-4.67187,26.92749-28.561,26.95654-66.14209C276.9856,112.33337,254.54175,88.37427,250,83.71973Z")'
    },
    "7.375%": {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283,4.88489-5.33838,25.6991-29.27039,25.72668-66.14209C275.75427,113.04358,254.87073,89.04248,250,83.71973Z")'
    },
    "7.5%": {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283,5.21741-6.16089,24.24756-30.10864,24.27332-66.14209C274.29919,113.88293,255.20618,89.86743,250,83.71973Z")'
    },
    "7.675%": {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283a107.89362,107.89362,0,0,0,0-132.43066Z")'
    },
    "7.75%": {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283a114.75362,114.75362,0,0,0,0-132.43066Z")'
    },
    "7.875%": {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283a122.94726,122.94726,0,0,0,0-132.43066Z")'
    },
    "8%": {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283a132.8546,132.8546,0,0,0,0-132.43066Z")'
    },
    "8.125%": {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283a146.8449,146.8449,0,0,0,0-132.43066Z")'
    },
    "8.25%": {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283a162.53412,162.53412,0,0,0,0-132.43066Z")'
    },
    "8.375%": {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283a176.77787,176.77787,0,0,0,0-132.43066Z")'
    },
    "8.5%": {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283a190.72126,190.72126,0,0,0,0-132.43066Z")'
    },
    "8.675%": {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283a209.38934,209.38934,0,0,0,0-132.43066Z")'
    },
    "8.75%": {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283a243.39068,243.39068,0,0,0,0-132.43066Z")'
    },
    "8.875%": {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283a287.63741,287.63741,0,0,0,7.72668-66.14209A287.64184,287.64184,0,0,0,250,83.71973Z")'
    },
    "9%": {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283a379.21733,379.21733,0,0,0,5.82605-66.14209A379.21975,379.21975,0,0,0,250,83.71973Z")'
    },
    "9.125%": {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283a446.999,446.999,0,0,0,4.93164-66.14209A447.019,447.019,0,0,0,250,83.71973Z")'
    },
    "9.25%": {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283a632.02233,632.02233,0,0,0,3.47827-66.14209A632.01412,632.01412,0,0,0,250,83.71973Z")'
    },
    "9.375%": {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283,1.14221-20.97925,1.79993-43.05762,1.80127-66.14209C251.80261,126.87048,251.14465,104.74316,250,83.71973Z")'
    },
    "9.5%": {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283q.34423-32.76892.34778-66.14209Q250.34814,116.56226,250,83.71973Z")'
    },
    "9.675%": {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283q-.21093-33.07764-.21118-66.14209Q249.78864,116.86915,250,83.71973Z")'
    },
    "9.75%": {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283q-.75366-32.39721-.77014-66.14209Q249.22895,116.19605,250,83.71973Z")'
    },
    "9.875%": {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283-.99158-21.13013-1.55151-43.20068-1.55273-66.14209C248.446,127.01416,249.00635,104.89465,250,83.71973Z")'
    },
    "10%": {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283-1.39319-20.72046-2.22192-42.81445-2.22363-66.14209C247.77466,126.62622,248.60388,104.48315,250,83.71973Z")'
    },
    "10.125%": {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283-1.84094-20.23315-3.00378-42.36377-3.00623-66.14209C246.99133,126.17371,248.15527,103.99365,250,83.71973Z")'
    },
    "10.25%": {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283a580.5,580.5,0,0,1,0-132.43066Z")'
    },
    "10.375%": {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283a449.2223,449.2223,0,0,1,0-132.43066Z")'
    },
    '10.5%': {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283a310.44762,310.44762,0,0,1,0-132.43066Z")',
    },
    '10.675%': {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283a256.07738,256.07738,0,0,1,0-132.43066Z")',
    },
    '10.75%': {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283a223.138,223.138,0,0,1,0-132.43066Z")',
    },
    '10.875%': {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283a194.53487,194.53487,0,0,1,0-132.43066Z")',
    },
    '11%': {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283a170.24251,170.24251,0,0,1,0-132.43066Z")',
    },
    '11.125%': {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283a149.90395,149.90395,0,0,1,0-132.43066Z")',
    },
    '11.25%': {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283a136.79185,136.79185,0,0,1,0-132.43066Z")',
    },
    '11.375%': {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283a123.67812,123.67812,0,0,1,0-132.43066Z")',
    },
    '11.5%': {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283a112.43868,112.43868,0,0,1,0-132.43066Z")',
    },
    '11.675%': {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283a104.76814,104.76814,0,0,1,0-132.43066Z")',
    },
    '11.75%': {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283-4.94592-5.47632-25.45105-29.4137-25.47827-66.14209C224.49438,113.187,245.06787,89.18091,250,83.71973Z")',
    },
    '11.875%': {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283-4.5022-4.56677-27.126-28.44641-27.15527-66.14209C222.81543,112.21851,245.515,88.269,250,83.71973Z")',
    },
    '12%': {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283-4.20117-4.02344-28.24268-27.82935-28.27332-66.14209C221.696,111.61072,245.806,87.73633,250,83.71973Z")',
    },
    '12.125%': {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283-4.04077-3.63513-29.47107-27.30469-29.503-66.14209C220.465,111.08484,245.969,87.34619,250,83.71973Z")',
    },
    '12.25%': {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283-3.87695-3.31482-30.4762-26.85876-30.50928-66.14209C219.45764,110.63794,246.13513,87.02417,250,83.71973Z")',
    },
    '12.375%': {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283-3.68286-2.99329-31.48145-26.39783-31.5155-66.14209C218.45032,110.176,246.33179,86.70117,250,83.71973Z")',
    },
    '12.5%': {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283-3.4574-2.67151-32.48657-25.922-32.52173-66.14209C217.443,109.6991,246.55994,86.37769,250,83.71973Z")',
    },

    /* A bit of gap */

    '33%': {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283-3.4574-2.67151-32.48657-25.922-32.52173-66.14209C217.443,109.6991,246.55994,86.37769,250,83.71973Z")',
    },
    '33.0625%': {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283-3.68286-2.99329-31.48145-26.39783-31.5155-66.14209C218.45032,110.176,246.33179,86.70117,250,83.71973Z")',
    },
    '33.125%': {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283-3.87695-3.31482-30.4762-26.85876-30.50928-66.14209C219.45764,110.63794,246.13513,87.02417,250,83.71973Z")',
    },
    '33.1875%': {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283-4.04077-3.63513-29.47107-27.30469-29.503-66.14209C220.465,111.08484,245.969,87.34619,250,83.71973Z")',
    },
    '33.25%': {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283-4.20117-4.02344-28.24268-27.82935-28.27332-66.14209C221.696,111.61072,245.806,87.73633,250,83.71973Z")',
    },
    '33.3125%': {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283-4.5022-4.56677-27.126-28.44641-27.15527-66.14209C222.81543,112.21851,245.515,88.269,250,83.71973Z")',
    },
    '33.375%': {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283-4.94592-5.47632-25.45105-29.4137-25.47827-66.14209C224.49438,113.187,245.06787,89.18091,250,83.71973Z")',
    },
    '33.4375%': {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283a104.76814,104.76814,0,0,1,0-132.43066Z")',
    },
    '33.5%': {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283a112.43868,112.43868,0,0,1,0-132.43066Z")',
    },
    '33.5625%': {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283a123.67812,123.67812,0,0,1,0-132.43066Z")',
    },
    '33.625%': {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283a136.79185,136.79185,0,0,1,0-132.43066Z")',
    },
    '33.6875%': {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283a149.90395,149.90395,0,0,1,0-132.43066Z")',
    },
    '33.75%': {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283a170.24251,170.24251,0,0,1,0-132.43066Z")',
    },
    '33.8125%': {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283a194.53487,194.53487,0,0,1,0-132.43066Z")',
    },
    '33.875%': {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283a223.138,223.138,0,0,1,0-132.43066Z")',
    },
    '33.9375%': {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283a256.07738,256.07738,0,0,1,0-132.43066Z")',
    },
    '34%': {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283a310.44762,310.44762,0,0,1,0-132.43066Z")',
    },
    "34.0625%": {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283a449.2223,449.2223,0,0,1,0-132.43066Z")'
    },
    "34.125%": {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283a580.5,580.5,0,0,1,0-132.43066Z")'
    },
    "34.1875%": {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283-1.84094-20.23315-3.00378-42.36377-3.00623-66.14209C246.99133,126.17371,248.15527,103.99365,250,83.71973Z")'
    },
    "34.25%": {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283-1.39319-20.72046-2.22192-42.81445-2.22363-66.14209C247.77466,126.62622,248.60388,104.48315,250,83.71973Z")'
    },

    "34.3125%": {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283-.99158-21.13013-1.55151-43.20068-1.55273-66.14209C248.446,127.01416,249.00635,104.89465,250,83.71973Z")'
    },
    "34.375%": {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283q-.75366-32.39721-.77014-66.14209Q249.22895,116.19605,250,83.71973Z")'
    },
    "34.4375%": {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283q-.21093-33.07764-.21118-66.14209Q249.78864,116.86915,250,83.71973Z")'
    },
    "34.5%": {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283q.34423-32.76892.34778-66.14209Q250.34814,116.56226,250,83.71973Z")'
    },
    "34.5625%": {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283,1.14221-20.97925,1.79993-43.05762,1.80127-66.14209C251.80261,126.87048,251.14465,104.74316,250,83.71973Z")'
    },
    "34.625%": {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283a632.02233,632.02233,0,0,0,3.47827-66.14209A632.01412,632.01412,0,0,0,250,83.71973Z")'
    },
    "34.6875%": {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283a446.999,446.999,0,0,0,4.93164-66.14209A447.019,447.019,0,0,0,250,83.71973Z")'
    },
    "34.75%": {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283a379.21733,379.21733,0,0,0,5.82605-66.14209A379.21975,379.21975,0,0,0,250,83.71973Z")'
    },
    "34.8125%": {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283a287.63741,287.63741,0,0,0,7.72668-66.14209A287.64184,287.64184,0,0,0,250,83.71973Z")'
    },
    "34.875%": {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283a243.39068,243.39068,0,0,0,0-132.43066Z")'
    },
    "34.9375%": {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283a209.38934,209.38934,0,0,0,0-132.43066Z")'
    },
    "35%": {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283a190.72126,190.72126,0,0,0,0-132.43066Z")'
    },
    "35.0625%": {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283a176.77787,176.77787,0,0,0,0-132.43066Z")'
    },
    "35.125%": {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283a162.53412,162.53412,0,0,0,0-132.43066Z")'
    },
    "35.1875%": {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283a146.8449,146.8449,0,0,0,0-132.43066Z")'
    },
    "35.25%": {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283a132.8546,132.8546,0,0,0,0-132.43066Z")'
    },
    "35.3125%": {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283a122.94726,122.94726,0,0,0,0-132.43066Z")'
    },
    "35.375%": {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283a114.75362,114.75362,0,0,0,0-132.43066Z")'
    },
    "35.4375%": {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283a107.89362,107.89362,0,0,0,0-132.43066Z")'
    },
    "35.5%": {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283,5.21741-6.16089,24.24756-30.10864,24.27332-66.14209C274.29919,113.88293,255.20618,89.86743,250,83.71973Z")'
    },
    "35.5625%": {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283,4.88489-5.33838,25.6991-29.27039,25.72668-66.14209C275.75427,113.04358,254.87073,89.04248,250,83.71973Z")'
    },
    "35.625%": {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283,4.55859-4.67187,26.92749-28.561,26.95654-66.14209C276.9856,112.33337,254.54175,88.37427,250,83.71973Z")'
    },
    "35.6875%": {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283,4.19836-4.01562,28.26746-27.819,28.2981-66.14209C278.32874,111.60034,254.19116,87.72852,250,83.71973Z")'
    },
    "35.75%": {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283,4.06958-3.69812,29.27258-27.391,29.30432-66.14209C279.33606,111.17139,254.0603,87.40942,250,83.71973Z")'
    },
    "35.8125%": {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283,3.93066-3.41382,30.166-26.99792,30.19873-66.14209C280.23145,110.77747,253.91943,87.12378,250,83.71973Z")'
    },
    "35.875%": {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283,3.72363-3.05688,31.28284-26.49,31.31677-66.14209C281.35071,110.26843,253.70959,86.76514,250,83.71973Z")'
    },
    "35.9375%": {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283,3.55591-2.80652,32.06458-26.12353,32.09937-66.14209C282.13416,109.90112,253.53979,86.51343,250,83.71973Z")'
    },
    "36%": {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283,3.39685-2.592,32.73462-25.80212,32.77014-66.14209C282.80566,109.5791,253.37866,86.2981,250,83.71973Z")'
    },
    "36.0625%": {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283,3.149-2.2926,33.67285-25.34668,33.704-66.15283C283.73523,109.11426,253.13123,85.99927,250,83.71973Z")'
    },
    "36.125%": {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283,3.149-2.2926,33.67285-25.34668,33.704-66.15283C283.73523,109.11426,253.13123,85.99927,250,83.71973Z")'
    },
    "36.1875%": {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283,3.149-2.2926,33.67285-25.34668,33.704-66.15283C283.73523,109.11426,253.13123,85.99927,250,83.71973Z")'
    },
    "36.25%": {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283,3.39685-2.592,32.73462-25.80212,32.77014-66.14209C282.80566,109.5791,253.37866,86.2981,250,83.71973Z")'
    },
    "36.3125%": {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283,3.55591-2.80652,32.06458-26.12353,32.09937-66.14209C282.13416,109.90112,253.53979,86.51343,250,83.71973Z")'
    },
    "36.375%": {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283,3.72363-3.05688,31.28284-26.49,31.31677-66.14209C281.35071,110.26843,253.70959,86.76514,250,83.71973Z")'
    },
    "36.4375%": {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283,3.93066-3.41382,30.166-26.99792,30.19873-66.14209C280.23145,110.77747,253.91943,87.12378,250,83.71973Z")'
    },
    "36.5%": {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283,4.06958-3.69812,29.27258-27.391,29.30432-66.14209C279.33606,111.17139,254.0603,87.40942,250,83.71973Z")'
    },
    "36.5625%": {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283,4.19836-4.01562,28.26746-27.819,28.2981-66.14209C278.32874,111.60034,254.19116,87.72852,250,83.71973Z")'
    },
    "36.625%": {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283,4.55859-4.67187,26.92749-28.561,26.95654-66.14209C276.9856,112.33337,254.54175,88.37427,250,83.71973Z")'
    },
    "36.6875%": {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283,4.88489-5.33838,25.6991-29.27039,25.72668-66.14209C275.75427,113.04358,254.87073,89.04248,250,83.71973Z")'
    },
    "36.75%": {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283,5.21741-6.16089,24.24756-30.10864,24.27332-66.14209C274.29919,113.88293,255.20618,89.86743,250,83.71973Z")'
    },
    "36.8125%": {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283a107.89362,107.89362,0,0,0,0-132.43066Z")'
    },
    "36.875%": {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283a114.75362,114.75362,0,0,0,0-132.43066Z")'
    },
    "36.9375%": {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283a122.94726,122.94726,0,0,0,0-132.43066Z")'
    },
    "37%": {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283a132.8546,132.8546,0,0,0,0-132.43066Z")'
    },
    "37.0625%": {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283a146.8449,146.8449,0,0,0,0-132.43066Z")'
    },
    "37.125%": {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283a162.53412,162.53412,0,0,0,0-132.43066Z")'
    },
    "37.1875%": {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283a176.77787,176.77787,0,0,0,0-132.43066Z")'
    },
    "37.25%": {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283a190.72126,190.72126,0,0,0,0-132.43066Z")'
    },
    "37.3125%": {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283a209.38934,209.38934,0,0,0,0-132.43066Z")'
    },
    "37.375%": {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283a243.39068,243.39068,0,0,0,0-132.43066Z")'
    },
    "37.4375%": {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283a287.63741,287.63741,0,0,0,7.72668-66.14209A287.64184,287.64184,0,0,0,250,83.71973Z")'
    },
    "37.5%": {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283a379.21733,379.21733,0,0,0,5.82605-66.14209A379.21975,379.21975,0,0,0,250,83.71973Z")'
    },
    "37.5625%": {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283a446.999,446.999,0,0,0,4.93164-66.14209A447.019,447.019,0,0,0,250,83.71973Z")'
    },
    "37.625%": {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283a632.02233,632.02233,0,0,0,3.47827-66.14209A632.01412,632.01412,0,0,0,250,83.71973Z")'
    },
    "37.6875%": {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283,1.14221-20.97925,1.79993-43.05762,1.80127-66.14209C251.80261,126.87048,251.14465,104.74316,250,83.71973Z")'
    },
    "37.75%": {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283q.34423-32.76892.34778-66.14209Q250.34814,116.56226,250,83.71973Z")'
    },
    "37.8125%": {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283q-.21093-33.07764-.21118-66.14209Q249.78864,116.86915,250,83.71973Z")'
    },
    "37.875%": {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283q-.75366-32.39721-.77014-66.14209Q249.22895,116.19605,250,83.71973Z")'
    },
    "37.9375%": {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283-.99158-21.13013-1.55151-43.20068-1.55273-66.14209C248.446,127.01416,249.00635,104.89465,250,83.71973Z")'
    },
    "38%": {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283-1.39319-20.72046-2.22192-42.81445-2.22363-66.14209C247.77466,126.62622,248.60388,104.48315,250,83.71973Z")'
    },
    "38.0625%": {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283-1.84094-20.23315-3.00378-42.36377-3.00623-66.14209C246.99133,126.17371,248.15527,103.99365,250,83.71973Z")'
    },
    "38.125%": {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283a580.5,580.5,0,0,1,0-132.43066Z")'
    },
    "38.1875%": {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283a449.2223,449.2223,0,0,1,0-132.43066Z")'
    },
    '38.25%': {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283a310.44762,310.44762,0,0,1,0-132.43066Z")',
    },
    '38.3125%': {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283a256.07738,256.07738,0,0,1,0-132.43066Z")',
    },
    '38.375%': {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283a223.138,223.138,0,0,1,0-132.43066Z")',
    },
    '38.4375%': {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283a194.53487,194.53487,0,0,1,0-132.43066Z")',
    },
    '38.5%': {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283a170.24251,170.24251,0,0,1,0-132.43066Z")',
    },
    '38.5625%': {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283a149.90395,149.90395,0,0,1,0-132.43066Z")',
    },
    '38.625%': {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283a136.79185,136.79185,0,0,1,0-132.43066Z")',
    },
    '38.6875%': {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283a123.67812,123.67812,0,0,1,0-132.43066Z")',
    },
    '38.75%': {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283a112.43868,112.43868,0,0,1,0-132.43066Z")',
    },
    '38.8125%': {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283a104.76814,104.76814,0,0,1,0-132.43066Z")',
    },
    '38.875%': {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283-4.94592-5.47632-25.45105-29.4137-25.47827-66.14209C224.49438,113.187,245.06787,89.18091,250,83.71973Z")',
    },
    '38.9375%': {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283-4.5022-4.56677-27.126-28.44641-27.15527-66.14209C222.81543,112.21851,245.515,88.269,250,83.71973Z")',
    },
    '39%': {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283-4.20117-4.02344-28.24268-27.82935-28.27332-66.14209C221.696,111.61072,245.806,87.73633,250,83.71973Z")',
    },
    '39.0625%': {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283-4.04077-3.63513-29.47107-27.30469-29.503-66.14209C220.465,111.08484,245.969,87.34619,250,83.71973Z")',
    },
    '39.125%': {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283-3.87695-3.31482-30.4762-26.85876-30.50928-66.14209C219.45764,110.63794,246.13513,87.02417,250,83.71973Z")',
    },
    '39.1875%': {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283-3.68286-2.99329-31.48145-26.39783-31.5155-66.14209C218.45032,110.176,246.33179,86.70117,250,83.71973Z")',
    },
    '39.25%': {
      d: 'path("M250,83.71973c-3.13123,2.27954-33.73523,25.39453-33.704,66.27783.03113,40.80615,30.55493,63.86023,33.704,66.15283-3.4574-2.67151-32.48657-25.922-32.52173-66.14209C217.443,109.6991,246.55994,86.37769,250,83.71973Z")',
    },
  },
}));

const Eyelid = (
  {
    transform = eyePosition.transform,
  }: {
    transform?: string,
  }
) => {
  return (<StyledPath transform={transform} />)
};

export default Eyelid;