import React from 'react'
import Eyelid from '../../../../components/Eye/Eyelid'

const Common = () => {
  return (
    <g>
      <g id="left__eyelid"
        style={{
          transformOrigin: 'center center',
          transform: 'scale(0.59) rotate(90deg) translate(0px, 508px)',
        }}
      >
        <Eyelid />
      </g>
      <g id="right__eyelid"
        style={{
          transformOrigin: 'center center',
          transform: 'scale(0.59) rotate(90deg) translate(0px, 130px)',
        }}
      >
        <Eyelid />
      </g>
      <g id="eye_outline">
        <path className="eye__stroke" d="M245.02972,100.38834a7.98893,7.98893,0,0,1,10.14,0,73.68484,73.68484,0,0,1,9.119,9.02295,97.0474,97.0474,0,0,1,6.95868,9.02288,96.07024,96.07024,0,0,1,9.63684,18.04615,93.93991,93.93991,0,0,1,6.83655,36.092,96.68178,96.68178,0,0,1-7.08984,36.092,97.97244,97.97244,0,0,1-16.4784,27.069,74.32659,74.32659,0,0,1-8.98291,9.023,7.90872,7.90872,0,0,1-10.13989,0,74.33092,74.33092,0,0,1-8.98291-9.023,97.97078,97.97078,0,0,1-16.47833-27.069,96.68226,96.68226,0,0,1-7.08985-36.092,93.94116,93.94116,0,0,1,6.83649-36.092,96.07026,96.07026,0,0,1,9.6369-18.04615,97.05608,97.05608,0,0,1,6.95868-9.023A73.69476,73.69476,0,0,1,245.02972,100.38834Z" />
        <path className="eye__stroke" d="M298.539,287.15143a97.05475,97.05475,0,0,1,9.023-6.95868,96.07018,96.07018,0,0,1,18.04614-9.6369,93.941,93.941,0,0,1,36.092-6.83649,96.68226,96.68226,0,0,1,36.09205,7.08984,97.97081,97.97081,0,0,1,27.069,16.47834,74.33092,74.33092,0,0,1,9.023,8.98291,7.90872,7.90872,0,0,1,0,10.13989,74.32659,74.32659,0,0,1-9.023,8.98291,97.97241,97.97241,0,0,1-27.069,16.47839,96.68161,96.68161,0,0,1-36.09205,7.08985,93.94013,93.94013,0,0,1-36.092-6.83655A96.07015,96.07015,0,0,1,307.562,322.4881a97.04754,97.04754,0,0,1-9.02289-6.95868,73.68692,73.68692,0,0,1-9.02295-9.119,7.989,7.989,0,0,1,0-10.14A73.69345,73.69345,0,0,1,298.539,287.15143Z" />
        <path className="eye__stroke" d="M210.65938,296.2705a7.989,7.989,0,0,1,0,10.14,73.68692,73.68692,0,0,1-9.023,9.119,97.04439,97.04439,0,0,1-9.02289,6.95867A96.07078,96.07078,0,0,1,174.5674,332.125a93.94012,93.94012,0,0,1-36.092,6.83654,96.68171,96.68171,0,0,1-36.092-7.08984,97.97236,97.97236,0,0,1-27.069-16.47839,74.3266,74.3266,0,0,1-9.023-8.98291,7.90871,7.90871,0,0,1,0-10.1399,74.33092,74.33092,0,0,1,9.023-8.98291,97.97072,97.97072,0,0,1,27.069-16.47833,96.68217,96.68217,0,0,1,36.092-7.08984,93.94119,93.94119,0,0,1,36.092,6.83648,96.07049,96.07049,0,0,1,18.04614,9.63691,97.05,97.05,0,0,1,9.023,6.95868A73.69406,73.69406,0,0,1,210.65938,296.2705Z" />
      </g>
    </g>
  )
}

export default Common